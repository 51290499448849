import React, { useContext } from 'react'
import { Button } from '@material-ui/core';
import electricCarIcon from '../../images/icons/electric.svg'
import paymentIcon from '../../images/icons/payment.svg'
import supportIcon from '../../images/icons/support.svg'
import card from "../../images/cartao.jpg";
import { Link } from 'gatsby';
import { AppContext } from '../../contexts/AppContext';
import Footer from '../../components/layout/Footer';
import ScrollToTop from '../../components/common/ScrollToTop';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

export default function CardLandingPage() {

    const { appState, sendEvent } = useContext(AppContext);

    const Feature = ({ image, title, text }) => {
        return <div className="featuretile-cont">
            <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={image}></img>
            </div>
            <div>
                <h3>{title}</h3>
            </div>
            <div>
                <span>
                    {text}
                </span>
            </div>
        </div>
    }

    return (
        <Layout>
            <SEO title="Cartão Carregamento Carro Elétrico" />
            <div className="vbox">
                <ScrollToTop />
                <div className="landingpage-cont">
                    <div className="landingpage-text-container">
                        <div style={{ padding: "0 10% 80px 10% ", display: "flex", flexDirection: "column" }}>
                            <h1 className="landingpage-title">{appState.i18n["landing"]["title"]}</h1>
                            <h2 className="landingpage-subtext">{appState.i18n["landing"]["subtext"]}</h2>
                            <Link className="landingpage-gotoregister-link" to="/register" onClick={() => sendEvent("Navigation", "Card Landing Page", "Registration")}><Button className="button-default shadow-lightBG" variant="contained" >{appState.i18n["get_card"].toUpperCase()}</Button></Link>
                        </div>
                    </div>
                    <div className="landingpage-image-container">

                    </div>
                </div>
                <div className="lp-card-cont">
                    <div className="lp-card-img-cont">
                        <img className="card-img shadow" src={card} alt="evaz card" />
                    </div>
                    <div className="lp-card-featurelist">
                        <ul className="lp-list">
                            <li>{appState.i18n.landing.cardFeature1}</li>
                            <li>{appState.i18n.landing.cardFeature2}</li>
                            <li>{appState.i18n.landing.cardFeature3}</li>
                            <li>{appState.i18n.landing.cardFeature4}</li>
                        </ul>
                    </div>
                </div>
                <div className="features-cont hbox">
                    <Feature image={supportIcon} title={appState.i18n.landing.supportTitle} text={appState.i18n.landing.supportText} />
                    <Feature image={electricCarIcon} title={appState.i18n.landing.electricCarTitle} text={appState.i18n.landing.electricCarText} />
                    <Feature image={paymentIcon} title={appState.i18n.landing.paymentTitle} text={appState.i18n.landing.paymentText} />
                </div>
                <div>
                    {/* <About /> */}
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </Layout>
    )
}
